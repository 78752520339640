<template>
  <div class="info-detail">
    <el-tabs v-model="activeName" type="card" @tab-click="handleClick">
      <el-tab-pane label="基本信息" name="1">
        <el-form
          class="form-con-center3"
          :model="dataForm"
          :rules="rules"
          ref="dataForm"
          label-width="100px"
          v-if="!isEdit"
        >
          <el-form-item label="问卷分类" prop="tableModel">
            <el-cascader
              v-model="array"
              :options="menuList"
              :props="defaultProps"
              ref="elcascader"
              @change="handleChange"
              popper-class="theder"
              style="width: 100%"
            ></el-cascader>
          </el-form-item>
          <el-form-item label="表单名称" prop="tableName">
            <el-input
              placeholder="请输入名称"
              v-model="dataForm.tableName"
            ></el-input>
          </el-form-item>
          <el-form-item label="表单权限" prop="authorityType">
            <el-select v-model="dataForm.authorityType" style="width: 100%">
              <el-option :value="1" label="团队内共享">团队内共享</el-option>
              <el-option :value="2" label="仅自己使用">仅自己使用</el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="表单权重">
            <el-input
              placeholder="默认为0，数字越大，排序越靠前"
              type="number"
              v-model="dataForm.orderWeight"
            ></el-input>
          </el-form-item>
          <el-form-item class="last-el-form-item">
            <el-button type="primary" @click="submitSubject(1)">保存</el-button>
            <el-button @click="submitSubject(2)" v-if="publishStatus != 1"
              >保存并配置题目</el-button
            >
          </el-form-item>
        </el-form>
        <el-form
          class="form-text-center"
          v-else
        >
          <el-form-item label="问卷分类"
            >{{ dataForm.tableModelName
            }}{{ dataForm.tableTypeName }}</el-form-item
          >
          <el-form-item label="表单名称">{{ dataForm.tableName }}</el-form-item>
          <el-form-item label="表单权限">{{
            dataForm.authorityTypeName
          }}</el-form-item>
          <el-form-item label="表单权重">{{
            dataForm.orderWeight
          }}</el-form-item>
          <el-form-item class="last-el-form-item" style="padding-left: 64px;">
            <el-button type="primary" @click="isEdit = false">编辑</el-button>
          </el-form-item>
        </el-form>
      </el-tab-pane>
      <el-tab-pane label="问卷设置" name="2">
        <div class="set-question">
          <div class="tip-con" v-if="dataForm.tableContent">
            <img src="~@/assets/img/tip.png" />
            <span>您正在管理{{ dataForm.tableName }}问卷的信息</span>
            <span style="padding: 0 16px">您当前已经完成题目配置</span>
            <el-button type="text" style="font-weight: bold" @click="toFormInfo"
              >去查看></el-button
            >
          </div>
          <div class="tip-con tip-con2" v-else>
            <img src="~@/assets/img/tip2.png" />
            <span>您正在管理{{ dataForm.tableName }}问卷的信息</span>
            <span style="padding: 0 16px">您当前尚未完成题目配置</span>
            <el-button
              type="text"
              style="font-weight: bold; color: #ff9f62"
              @click="toFormInfo"
              >去查看></el-button
            >
          </div>
          <el-form
            :model="dataForm"
            :rules="rules"
            ref="dataForm"
            label-width="90px"
            style="padding-top: 20px; width: 30%"
            v-if="!isEditSetQuestion"
          >
            <el-form-item label="问卷规则" prop="tableRule">
              <el-select
                :disabled="dataForm.publishStatus == 1"
                v-model="dataForm.tableRule"
                style="width: 100%"
                placeholder="请选择"
              >
                <el-option
                  v-for="(item, index) in tableRuleList"
                  :key="index"
                  :value="item.value"
                  :label="item.name"
                  >{{ item.name }}</el-option
                >
              </el-select>
            </el-form-item>
            <el-form-item label="是否发布" prop="publishStatus">
              <el-select
                style="width: 100%"
                v-model="dataForm.publishStatus"
                @change="changePublishStatus"
                v-if="publishStatus != 1"
              >
                <el-option :value="1" label="是">是</el-option>
                <el-option :value="2" label="否">否</el-option>
              </el-select>
              <span v-else>{{
                dataForm.publishStatus == 1 ? "是" : "否"
              }}</span>
            </el-form-item>
            <el-form-item
              label="是否启用"
              prop="isUse"
              v-if="dataForm.publishStatus == 1"
            >
              <el-select style="width: 100%" v-model="dataForm.isUse">
                <el-option :value="1" label="是">是</el-option>
                <el-option :value="2" label="否">否</el-option>
              </el-select>
            </el-form-item>
          </el-form>
          <el-form v-else style="padding-top: 10px">
            <el-form-item label="问卷规则">{{
              dataForm.tableRuleName
            }}</el-form-item>
            <el-form-item label="是否发布">{{
              dataForm.publishStatus == 1 ? "是" : "否"
            }}</el-form-item>
            <el-form-item label="是否启用">{{
              dataForm.isUse == 1 ? "是" : "否"
            }}</el-form-item>
          </el-form>
          <el-divider></el-divider>
          <div class="set-score">
            <div class="title">
              <el-button
                type="primary"
                v-if="!isEditSetQuestion"
                @click="addScore"
              >
                <img src="" />分值设置
              </el-button>
              <span v-else>分值设置</span>
            </div>
            <el-table :data="dataList" v-if="dataList.length">
              <el-table-column label="分值区间" width="180">
                <template slot-scope="scope"
                  >分值区间{{ scope.$index + 1 }}</template
                >
              </el-table-column>
              <el-table-column label="分值下限" width="180">
                <template slot-scope="scope">
                  <el-input
                    v-model="scope.row.lowLimit"
                    v-if="!isEditSetQuestion"
                    placeholder="请输入下限"
                    type="number"
                    v-int
                    @blur="
                      (val) => {
                        changeLowLimit(
                          scope.row.lowLimit,
                          scope.row.upLimit,
                          scope.$index,
                          '1'
                        );
                      }
                    "
                  ></el-input>
                  <span v-else>{{ scope.row.lowLimit }}</span>
                </template>
              </el-table-column>
              <el-table-column label="分值上限" width="180">
                <template slot-scope="scope">
                  <el-input
                    v-model="scope.row.upLimit"
                    type="number"
                    v-int
                    @blur="
                      (val) => {
                        changeLowLimit(
                          scope.row.lowLimit,
                          scope.row.upLimit,
                          scope.$index,
                          '2'
                        );
                      }
                    "
                    v-if="!isEditSetQuestion"
                    placeholder="请输入上限"
                  ></el-input>
                  <span v-else>{{ scope.row.lowLimit }}</span>
                </template>
              </el-table-column>
              <el-table-column label="说明">
                <template slot-scope="scope">
                  <el-input
                    v-model="scope.row.description"
                    v-if="!isEditSetQuestion"
                    placeholder="请输入分值对应的说明"
                  ></el-input>
                  <span v-else>{{ scope.row.description }}</span>
                </template>
              </el-table-column>
              <el-table-column
                header-align="right"
                align="right"
                label="操作"
                width="120"
              >
                <template slot-scope="scope">
                  <el-button
                    type="text"
                    @click="deleteRow(scope.row, scope.$index)"
                  >
                    <img
                      style="width: 14px"
                      src="~@/assets/img/delete-btn.png"
                    />
                  </el-button>
                </template>
              </el-table-column>
            </el-table>
            <div class="no-data" v-else>
              <img src="~@/assets/img/blank.png" />
              <span v-if="isEditSetQuestion"
                >还没有配置任何分值区间哦，请点击编辑进行配置吧</span
              >
              <span v-else>
                还没有配置任何分值区间哦，请点击分值设置进行添加吧
                请注意分值区间不要有重叠
              </span>
            </div>
            <div class="edit-btn">
              <el-button
                type="primary"
                @click="isEditSetQuestion = false"
                v-if="isEditSetQuestion"
                >编辑</el-button
              >
              <el-button type="primary" @click="submitSubject()" v-else
                >保存</el-button
              >
            </div>
          </div>
        </div>
      </el-tab-pane>
      <el-tab-pane label="问卷数据" name="3">
        <div class="question-data">
          <div class="tip-con">
            <img src="~@/assets/img/tip.png" />
            <span>您正在管理{{ dataForm.tableName }}问卷的信息</span>
          </div>
          <el-form
            :model="dataForm"
            :rules="rules"
            ref="dataForm"
            :inline="true"
            label-width="90px"
            style="padding-top: 24px"
            class="form-content"
          >
            <el-form-item label="发送方式">
              <el-select v-model="sendType">
                <el-option :value="1" label="随访">随访</el-option>
                <el-option :value="2" label="方案">方案</el-option>
                <el-option :value="3" label="系统自动发送"
                  >系统自动发送</el-option
                >
                <el-option :value="4" label="访视">访视</el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="随访名称">
              <el-select v-model="followUpName" multiple clearable>
                <el-option
                  v-for="(item, index) in followUpList"
                  :value="item.id"
                  :label="item.followUpName"
                  :key="index"
                  >{{ item.followUpName }}</el-option
                >
              </el-select>
            </el-form-item>
            <el-form-item label="方案名称">
              <el-select v-model="planName" multiple clearable>
                <el-option
                  v-for="(item, index) in planList"
                  :value="item.id"
                  :label="item.programName"
                  :key="index"
                  >{{ item.programName }}</el-option
                >
              </el-select>
            </el-form-item>
            <el-form-item label="填写人">
              <el-input
                placeholder="输入关键字查询"
                v-model="signBy"
                style="width: 300px"
              >
                <el-button
                  slot="append"
                  @click="getDataList"
                  icon="el-icon-search"
                ></el-button>
              </el-input>
            </el-form-item>
            <el-form-item label="提交时间">
              <el-date-picker
                v-model="rangeTime"
                type="daterange"
                range-separator="至"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
                value-format="yyyy-MM-dd HH:mm:ss"
              ></el-date-picker>
            </el-form-item>
          </el-form>
          <div class="search-list" style="padding-left: 20px">
            <el-button type="primary" @click="getDataList">搜索</el-button>
            <el-button type="info" @click="resetValue">重置</el-button>
          </div>
          <el-table :data="tableData">
            <el-table-column
              prop="patientName"
              label="填写人"
              width="120"
            ></el-table-column>
            <el-table-column
              prop="createTime"
              label="提交时间"
              width="180"
            ></el-table-column>
            <el-table-column label="发送方式" width="120">
              <template slot-scope="scope">
                <span v-if="scope.row.type == 1">随访</span>
                <span v-if="scope.row.type == 2">方案</span>
                <span v-if="scope.row.type == 3">访视</span>
                <span v-if="scope.row.type == 4">系统自动发送</span>
              </template>
            </el-table-column>
            <el-table-column label="详细信息">
              <template slot-scope="scope">
                <span v-if="scope.row.type == 1">{{ scope.row.taskName }}</span>
                <span v-if="scope.row.type == 2"></span>
              </template>
            </el-table-column>
            <el-table-column prop="createName" label="发送人" width="120">
              <template slot-scope="scope">
                <span v-if="scope.row.type == 4">系统自动发送</span>
                <span v-else>{{ scope.row.createName }}</span>
              </template>
            </el-table-column>
            <el-table-column
              prop="createTime"
              label="发送时间"
              width="180"
            ></el-table-column>
            <el-table-column
              header-align="right"
              align="right"
              label="操作"
              width="100"
            >
              <template slot-scope="scope">
                <el-button type="text" @click="handleFormInfo(scope.row)"
                  >查看详情</el-button
                >
              </template>
            </el-table-column>
          </el-table>
        </div>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
import qs from "qs";
import moment from "moment";
export default {
  data() {
    return {
      activeName: "1",
      isEdit: true,
      flag: 1,
      array: [],
      id: "",
      dataForm: {
        hospitalId: "", //医院id
        teamId: "", //团队id
        tableName: "", //表单名称
        tableBelongs: 1, //表单权限
        orderWeight: "", //权重
        tableType: "",
        tableModel: "",
        authorityType: 1,
        tableRule: "",
        // publishStatus: 2,
      },
      rules: {
        tableName: [
          { required: true, message: "请输入表单名称", trigger: "blur" },
        ],
        authorityType: [
          {
            required: true,
            message: "请选择表单权限",
            trigger: "change",
          },
        ],
        tableModel: [
          { required: true, message: "请选择问卷分类", trigger: "change" },
        ],
        tableRule: [
          { required: true, message: "请选择问卷规则", trigger: "change" },
        ],
        publishStatus: [
          { required: true, message: "请选择是否发布", trigger: "change" },
        ],
        isUse: [
          { required: true, message: "请选择是否启用", trigger: "change" },
        ],
      },
      menuList: [],
      defaultProps: {
        children: "list",
        label: "name",
        expandTrigger: "hover",
        checkStrictly: true,
        emitPath: true,
      },
      tableRuleList: [], //表单规则
      dataList: [],
      isEditSetQuestion: true, //问卷设置是否为编辑状态
      sendType: "", //发送方式
      followUpName: "",
      followUpList: [], //随访列表
      planName: [], //
      planList: [], //方案列表
      signBy: "", //填写人
      tableData: [], //列表接口
      publishStatus: "",
      rangeTime: [],
    };
  },
  mounted() {
    if (this.$route.query.id) {
      this.id = this.$route.query.id;
      this.getInfo();
      this.getDictList();
      this.getDictList2();
    }
    if (this.$route.query.activeName) {
      this.activeName = this.$route.query.activeName.toString();
      if (this.activeName == "3") {
        this.getFollowUpList();
        this.getPlanList();
        this.getDataList();
      }
    }
  },
  methods: {
    // 查看详情
    toFormInfo() {
      if (this.dataForm.publishStatus == 1) {
        this.$router.push({
          name: "formInfo",
          query: { id: this.dataForm.id, activeName: this.activeName },
        });
      } else {
        localStorage.formId = this.id;
        window.open(this.$global.dominUrlvform + "/#/?formId=" + this.id);
      }
    },
    async getDictList2() {
      const { data: res } = await this.$httpAes({
        url: this.$httpAes.adornUrl("/dict/list"),
        method: "post",
        data: {
          stringParam1: "表单规则",
        },
      });
      this.tableRuleList = res.data;
    },
    handleClick(value) {
      this.isEdit = true;
      this.isEditSetQuestion = true;
      this.$router.push({
        name: "indicatorInfo",
        query: { id: this.id, activeName: this.activeName },
      });
      this.getInfo();
      if (this.activeName == "3") {
        this.getFollowUpList();
        this.getPlanList();
        this.getDataList();
      }
    },
    handleChange(node) {
      if (node.length) {
        this.dataForm.tableModel = node[0];
        if (node[1]) {
          this.dataForm.tableType = node[1];
        } else {
          this.dataForm.tableType = "";
        }
      }
    },
    // 获取字典
    async getDictList() {
      let _this = this;
      let teamsId = "";
      if (localStorage.currentTeam) {
        teamsId = JSON.parse(localStorage.currentTeam).id;
      }
      const { data } = await this.$http({
        url: _this.$http.adornUrl("/dict/getSysDicts?type=" + "表单类别"),
        method: "get",
        params: {
          teamsId,
          pageSize: 999,
        },
      });

      if (data.status) {
        _this.menuList = data.data;
        this.changeData(_this.menuList);
      }
    },

    changeData(arr) {
      for (var i = 0; i < arr.length; i++) {
        arr[i].list = arr[i].map.list;
        if (arr[i].map.list != null && arr[i].map.list.length != 0) {
          this.changeData(arr[i].map.list);
        }
        if (arr[i].map.list.length < 1) {
          arr[i].list = undefined;
        }
      }
    },
    //编辑获取指标库详情
    async getInfo() {
      var that = this;
      const { data } = await this.$httpAes({
        url: that.$httpAes.adornUrl("/vfTableInfo/info"),
        method: "post",
        data: {
          stringParam1:this.id,
        },
      });
      if (data.status) {
        let info = data.data;
        this.dataForm = info;
        if (info.tableModel) {
          this.array[0] = info.tableModel;
        }
        if (info.tableType) {
          this.array[1] = info.tableType;
        }
        if (info.scoreSetting) {
          this.dataList = JSON.parse(info.scoreSetting);
        }
        this.publishStatus = info.publishStatus;
        console.log(this.dataList);
      } else {
        that.$message({
          message: data.data.msg,
          type: "error",
          duration: 1500,
          onClose: () => {},
        });
      }
    },
    // 是否发布改变
    changePublishStatus(val) {
      if (val == 1) {
        this.dataForm.isUse = 1;
      }
    },
    // 判断下限是否大于等于上限
    changeLowLimit(value, value2, index, type) {
      let countNum = 0;
      if (parseFloat(value) < parseFloat(value2)) {
        this.$message.error("下限值必须大于等于上限值");
        this.dataList[index].upLimit = "";
      } else {
        let curValue = type == "1" ? parseFloat(value) : parseFloat(value2);
        let newArr = this.dataList.filter((item, $index) => {
          return $index != index;
        });
        console.log(newArr);
        newArr.forEach((item) => {
          // 判断输入的值是否在其他下限和上限之间
          if (
            parseFloat(item.lowLimit) >= curValue &&
            curValue >= parseFloat(item.upLimit)
          ) {
            countNum++;
          }
          // 下限值失去焦点时，判断同级上限值是否小于其他组的上限值并且当前下限值同时大于其他组的下限值
          if (type == "1") {
            if (
              curValue > parseFloat(item.lowLimit) &&
              parseFloat(value2) < parseFloat(item.upLimit)
            ) {
              countNum++;
            }
          }
          // 上限值失去焦点时，判断同级下限值是否大于其他组的下限值并且当前上限值同时小于其他组的上限值
          if (type == "2") {
            if (
              curValue < parseFloat(item.upLimit) &&
              parseFloat(value) > parseFloat(item.lowLimit)
            ) {
              countNum++;
            }
          }
        });
        if (countNum > 0) {
          this.$message.error("分值区间存在重复请确认");
          if (type == "1") {
            this.dataList[index].lowLimit = "";
          } else {
            this.dataList[index].upLimit = "";
          }
        }
        console.log(countNum);
      }
    },
    //提交指标库
    submitSubject(type) {
      let _this = this;
      if (localStorage.currentTeam) {
        _this.dataForm.teamId = JSON.parse(localStorage.currentTeam).id;
      }
      if (localStorage.userInfo) {
        this.dataForm.hospitalId = JSON.parse(localStorage.userInfo).deptPid;
      }

      if (_this.unionId) {
        _this.dataForm.unionId = _this.unionId;
      }
      let params = {};
      // console.log(params);
      // return;
      _this.$refs["dataForm"].validate((valid) => {
        if (valid) {
          // 问卷信息提交
          if (this.activeName == 1) {
            params = {
              id: this.id,
              teamId: this.dataForm.teamId,
              hospitalId: this.dataForm.hospitalId,
              tableModel: this.dataForm.tableModel,
              tableType: this.dataForm.tableType,
              tableName: this.dataForm.tableName,
              authorityType: this.dataForm.authorityType,
              orderWeight: this.dataForm.orderWeight,
            };
            if (_this.flag == 1) {
              _this.flag = 0;
              _this
                .$httpAes({
                  url: _this.$httpAes.adornUrl("/vfTableInfo/update"),
                  method: "post",
                  data: params,
                })
                .then((data) => {
                  if (data.data.status) {
                    _this.$message({
                      message: data.data.msg,
                      type: "success",
                      duration: 1500,
                      onClose: () => {
                        if (type == 1) {
                          _this.flag = 1;
                          _this.isEdit = true;
                          _this.getInfo();
                        } else {
                          // 跳转至表单配置页面
                          localStorage.formId = this.id;
                          _this.isEdit = true;
                          window.open(
                            this.$global.dominUrlvform + "/#/?formId=" + this.id
                          );
                        }
                      },
                    });
                  } else {
                    _this.flag = 1;
                    _this.$message({
                      message: data.data.msg,
                      type: "error",
                      duration: 1500,
                      onClose: () => {},
                    });
                  }
                });
            }
          }
          // 问卷设置
          if (this.activeName == 2) {
            if (!_this.dataForm.tableRule) {
              _this.$message.error("请选择问卷规则");
              return;
            }
            params = {
              id: this.id,
              teamId: this.dataForm.teamId,
              hospitalId: this.dataForm.hospitalId,
              tableRule: this.dataForm.tableRule,
              publishStatus: this.dataForm.publishStatus,
              isUse: this.dataForm.isUse,
            };
            if (this.dataList.length) {
              let isNull = 0;
              this.dataList.forEach((ele) => {
                if (!(ele.lowLimit && ele.upLimit && ele.description)) {
                  isNull++;
                }
              });
              if (isNull > 0) {
                this.$message.error("请完善分值设置所有信息");
                return;
              }
              params.scoreSetting = JSON.stringify(this.dataList);
            }
            if (_this.dataForm.tableContent && params.publishStatus == 1) {
              _this
                .$confirm(
                  `您当前正在发布问卷，发布后不可更改问卷内容，请确认`,
                  "提示",
                  {
                    confirmButtonText: "确定",
                    cancelButtonClass: "btn-custom-cancel",
                    cancelButtonText: "取消",
                    type: "warning",
                  }
                )
                .then(() => {
                  if (_this.flag == 1) {
                    _this.flag = 0;
                    _this
                      .$httpAes({
                        url: _this.$httpAes.adornUrl("/vfTableInfo/update"),
                        method: "post",
                        data: params,
                      })
                      .then((data) => {
                        if (data.data.status) {
                          _this.$message({
                            message: data.data.msg,
                            type: "success",
                            duration: 1500,
                            onClose: () => {
                              _this.flag = 1;
                              _this.isEditSetQuestion = true;
                              _this.getInfo();
                            },
                          });
                        } else {
                          _this.flag = 1;
                          _this.$message({
                            message: data.data.msg,
                            type: "error",
                            duration: 1500,
                            onClose: () => {},
                          });
                        }
                      });
                  }
                });
            }
            if (!_this.dataForm.tableContent && params.publishStatus == 1) {
              _this
                .$confirm(`您当前问卷题目数量为0，无法进行发布请确认`, "提示", {
                  confirmButtonText: "确定",
                  cancelButtonClass: "btn-custom-cancel",
                  cancelButtonText: "取消",
                  type: "warning",
                })
                .then(() => {});
            } else {
            }
            if (params.publishStatus == 2) {
              if (_this.flag == 1) {
                _this.flag = 0;
                _this
                  .$httpAes({
                    url: _this.$httpAes.adornUrl("/vfTableInfo/update"),
                    method: "post",
                    data:params,
                  })
                  .then((data) => {
                    if (data.data.status) {
                      _this.$message({
                        message: data.data.msg,
                        type: "success",
                        duration: 1500,
                        onClose: () => {
                          _this.flag = 1;
                          _this.isEditSetQuestion = true;
                          _this.getInfo();
                        },
                      });
                    } else {
                      _this.flag = 1;
                      _this.$message({
                        message: data.data.msg,
                        type: "error",
                        duration: 1500,
                        onClose: () => {},
                      });
                    }
                  });
              }
            }
          }
        }
      });
    },
    // 问卷设置
    addScore() {
      let obj = {};
      this.dataList.push(obj);
    },
    // 删除分值配置
    deleteRow(row, index) {
      this.dataList.splice(index, 1);
    },
    // 获取随访列表名称
    async getFollowUpList() {
      const user = JSON.parse(localStorage.getItem("userInfo"));
      let teamsId = "";
      let enertyId = "";
      if (localStorage.currentTeam) {
        teamsId = JSON.parse(localStorage.currentTeam).id;
        enertyId = JSON.parse(localStorage.currentTeam).entityId;
      }
      const { data: res } = await this.$httpAes({
        url: this.$httpAes.adornUrl("/followUp/getFollowUpFeedback"),
        method: "post",
        data: {
          intParam1: 100000,
          intParam2: 1,
          stringParam4: teamsId,
          stringParam5: enertyId,
          intParam3: 1,
        },
      });
      if (res.status) {
        this.followUpList = res.data;
      }
    },
    async getPlanList() {
      let teamsId = "";
      let enertyId = "";
      if (localStorage.currentTeam) {
        teamsId = JSON.parse(localStorage.currentTeam).id;
        enertyId = JSON.parse(localStorage.currentTeam).entityId;
      }
      const user = JSON.parse(localStorage.getItem("userInfo"));
      const { data: res } = await this.$http({
        url: this.$http.adornUrl("/programInfo/list"),
        method: "post",
        data: {
          teamsId: teamsId,
          pageSize: 10000,
          pageNo: 1,
        },
      });
      if (res.status) {
        this.planList = res.data;
      }
    },
    // 获取填写信息列表
    async getDataList() {
      let teamsId = "";
      let enertyId = "";
      if (localStorage.currentTeam) {
        teamsId = JSON.parse(localStorage.currentTeam).id;
        enertyId = JSON.parse(localStorage.currentTeam).entityId;
      }
      const user = JSON.parse(localStorage.getItem("userInfo"));
      const { data: res } = await this.$httpAes({
        url: this.$httpAes.adornUrl("/vfRelationInfo/list"),
        method: "post",
        data: {
          stringParam2: this.id,
          stringParam1: teamsId,
          intParam1: 10000,
          intParam2: 1,
          intParam3: this.sendType,
          stringParam4: this.followUpName.length ? this.followUpName.join(",") : "",
          stringParam3: this.planName.length ? this.planName.join(",") : "",
          stringParam5: this.signBy,
          stringParam6:
            this.rangeTime && this.rangeTime.length ? this.rangeTime[0] : "",
          stringParam7:
            this.rangeTime && this.rangeTime.length ? this.rangeTime[1] : "",
        },
      });
      if (res.status) {
        this.tableData = res.data;

        console.log(this.tableData);
      }
    },
    resetValue() {
      this.sendType = "";
      this.followUpName = [];
      this.planName = [];
      this.signBy = "";
      this.rangeTime = [];
      this.getDataList();
    },
    handleFormInfo(row) {
      this.$router.push({
        name: "formInfo",
        query: { id: this.id, activeName: this.activeName },
      });
      localStorage.currentPatientFormInfo = JSON.stringify(row);
    },
  },
};
</script>

<style lang="scss">
.theder {
  .el-cascader-panel {
    .el-cascader-menu {
      .el-radio {
        height: 100%;
        width: 150px;
        opacity: 0;
        position: absolute;
        // z-index: 10;
        .el-radio__input {
          .el-radio__inner {
            border: 0px;
          }
        }
        .el-radio__input.is-checked {
          .el-radio__inner {
            background: none;
          }
        }
      }
    }
  }
}
.tip-con {
  display: flex;
  height: 40px;
  align-items: center;
  background: #e6f7ff;
  border: 1px solid #bae7ff;
  color: #666;
  padding-left: 16px;
  &.tip-con2 {
    background: #fff0e6;
    border: 1px solid #ff9f62;
  }
  img {
    width: 16px;
    height: 14px;
    margin-right: 8px;
  }
}
.set-question {
  .set-score {
    .title {
      font-size: 16px;
      font-weight: bold;
      padding-bottom: 24px;
    }
    .no-data {
      display: flex;
      flex-direction: column;
      align-items: center;
      color: #666;
      img {
        width: 250px;
        height: 130px;
      }
      span {
        display: inline-block;
        padding-top: 20px;
      }
    }
    .edit-btn {
      display: flex;
      align-items: center;
      justify-content: center;
      padding-top: 24px;
    }
  }
}
.el-date-editor .el-range-separator {
  width: 6%;
}
</style>
